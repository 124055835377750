function getEnv() {
  const hostname = location.hostname;

  if (hostname === "localhost") {
    return "localhost";
  }

  if (hostname.startsWith("stage.")) {
    return "stage";
  }

  return "production";
}

const configByEnv = {
  localhost: {
    apiWs: "ws://localhost:8080",
    apiUrl: "http://localhost:8080",
  },
  stage: {
    apiWs: "wss://stage.labs1503.api.topmonks.com",
    apiUrl: "https://stage.labs1503.api.topmonks.com",
  },
  production: {
    apiWs: "wss://labs1503.api.topmonks.com",
    apiUrl: "https://labs1503.api.topmonks.com",
  },
};

const env = getEnv();

export const config = { env: env, ...configByEnv[env] };
