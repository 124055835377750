import React from "react";
import { useTranslation } from "react-i18next";
import * as api from "../services/api";
import { useLatest } from "../hooks";
import AutocompleteSelect from "./AutocompleteSelect";
import { useSearchParams } from "react-router";

async function fetchLocationOptions(signal) {
  const res = await api.locationOptions({ signal });

  return res.data.locations.data;
}

function useLocationOptions() {
  const [options, setOptions] = React.useState();
  useLatest(async (signal) => {
    setOptions(null);
    setOptions(await fetchLocationOptions(signal));
  }, []);

  return React.useMemo(
    () => ({ options: options ?? [], loading: options == null }),
    [options],
  );
}

function useChangeQuery() {
  const [_, setSearchParams] = useSearchParams();
  const changeQuery = React.useCallback(
    (newValues) => {
      setSearchParams((prev) => ({
        ...Object.fromEntries(prev),
        ...Object.fromEntries(
          Object.entries(newValues).filter(([_, v]) => v != null),
        ),
      }));
    },
    [setSearchParams],
  );

  return changeQuery;
}

export function currentLocationFromRequest(request) {
  const url = new URL(request.url);

  return url.searchParams.get("g_location");
}

export function useCurrentLocation() {
  const [searchParams] = useSearchParams();

  return searchParams.get("g_location");
}

export function LocationSelector() {
  const { t } = useTranslation();
  const locationOptions = useLocationOptions();
  const changeQuery = useChangeQuery();
  const locationId = useCurrentLocation();

  React.useEffect(() => {
    if (locationId != null) {
      return;
    }

    const firstLocationId = locationOptions.options[0]?.id;
    if (firstLocationId == null) {
      return;
    }

    changeQuery({ g_location: firstLocationId });
  }, [locationId, locationOptions]);

  return (
    <AutocompleteSelect
      required
      label={t("calendar.selectLocation")}
      value={locationOptions.options.find((o) => o.id == locationId) ?? null}
      loading={locationOptions.loading}
      options={locationOptions.options}
      disabled={locationOptions.loading}
      onChange={async (value) => {
        const locationId = value?.id;
        changeQuery({ g_location: locationId });
      }}
    />
  );
}
