export default function formatDate(dateString) {
  if (!dateString) return "";

  const date = new Date(dateString.replace(" ", "T"));

  if (isNaN(date.getTime())) {
    console.warn("Invalid date:", dateString);
    return "";
  }

  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);

  const time = `${date.getHours()}:${String(date.getMinutes()).padStart(2, "0")}`;

  // Check if the date is today
  if (
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate()
  ) {
    return { type: "today", time };
  }

  // Check if the date is yesterday
  if (
    date.getFullYear() === yesterday.getFullYear() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getDate() === yesterday.getDate()
  ) {
    return { type: "yesterday", time };
  }

  // Return actual date
  return {
    type: "full",
    time,
  };
}
