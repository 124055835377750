const getValueByPath = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc?.[part], obj);
};

export const prefillFormData = (template, data) => {
  if (!template?.schema?.properties) return {};

  return Object.entries(template.schema.properties).reduce(
    (result, [fieldId, fieldDef]) => {
      if (fieldDef.prefill?.source) {
        const value = getValueByPath(data, fieldDef.prefill.source);
        if (value !== undefined) {
          result[fieldId] = value;
        }
      }
      return result;
    },
    {},
  );
};
