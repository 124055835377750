import { Box } from "@mui/material";
import NotificationsList from "../components/NotificationsPopUp/NotificationList";
import { useNotifications } from "../context/NotificationContext";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import TablePagination from "@mui/material/TablePagination";
import NotificationFilters from "../components/NotificationsPopUp/NotificationFilters";

/**
 * Notifications component displays a list of notifications with
 * options to filter by read status, sort by date, and paginate
 * results. It integrates with the NotificationsContext to manage
 * state and user interactions, allowing users to mark all notifications
 * as read/unread and search by notification category.
 *
 * @component
 * @returns {JSX.Element} Returns the Notifications component.
 */
export default function Notifications() {
  const {
    notifications,
    handleItemClick,
    markAllAsRead,
    fetchNotifications,
    loading,
  } = useNotifications();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortDirection, setSortDirection] = useState("desc");
  const [showUnreadOnly, setShowUnreadOnly] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchNotifications({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
    });
  }, [page, rowsPerPage, fetchNotifications]);

  // Filter notifications based on unread status
  const filteredNotifications = showUnreadOnly
    ? notifications.filter((item) => !item.isRead)
    : notifications;

  // Search notifications by type
  const searchedNotifications = filteredNotifications.filter(
    (notification) =>
      notification.category &&
      notification.category.toLowerCase().startsWith(searchTerm.toLowerCase()),
  );

  let emptyMessage = "";
  if (loading) {
    emptyMessage = t("client.notifications.loading");
  } else if (searchTerm && searchedNotifications.length === 0) {
    emptyMessage = t("client.notifications.noNotificationsForSearch");
  } else if (showUnreadOnly && filteredNotifications.length === 0) {
    emptyMessage = t("client.notifications.noUnreadNotifications");
  } else if (notifications.length === 0) {
    emptyMessage = t("client.notifications.noNotifications");
  } else {
    emptyMessage = t("client.notifications.emptyCategory");
  }

  // Sort notifications by date
  const sortedNotifications = [...searchedNotifications].sort((a, b) => {
    return sortDirection === "asc"
      ? new Date(a.date) - new Date(b.date)
      : new Date(b.date) - new Date(a.date);
  });

  const paginatedNotifications = sortedNotifications.slice(0, rowsPerPage);

  const allRead = paginatedNotifications.every((item) => item.isRead);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleMarkAll = () => {
    markAllAsRead(paginatedNotifications, allRead);
  };

  const toggleShowUnreadOnly = () => {
    setShowUnreadOnly((prev) => !prev);
    setPage(0);
    fetchNotifications({
      offset: 0,
      limit: rowsPerPage,
      filter: { isRead: false },
    });
  };

  const handleSortDirectionChange = (event) => {
    const newValue = event.target.value;
    if (newValue === "asc" || newValue === "desc") {
      setSortDirection(newValue);
      fetchNotifications({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        order: { [newValue]: "date" },
      });
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <NotificationFilters
        handleMarkAll={handleMarkAll}
        toggleShowUnreadOnly={toggleShowUnreadOnly}
        handleSortDirectionChange={handleSortDirectionChange}
        showUnreadOnly={showUnreadOnly}
        sortDirection={sortDirection}
        allRead={allRead}
        setSearchTerm={setSearchTerm}
        setPage={setPage}
      />
      <NotificationsList
        notifications={paginatedNotifications}
        handleItemClick={handleItemClick}
        emptyMessage={emptyMessage}
        loading={loading}
      />
      <Box sx={{ marginTop: 2 }}>
        <TablePagination
          labelRowsPerPage={t("pagination.rowsPerPage")}
          component="div"
          count={searchedNotifications.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Box>
    </Box>
  );
}
