import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRevalidator } from "react-router";
import { FormErrorList, useFormAction, formData } from "../../form";
import ButtonLoader from "../ButtonLoader";
import { createService } from "../../services/api";

export function CreateServiceDialog({ onClose, calendars }) {
  const { t } = useTranslation();
  const revalidator = useRevalidator();
  const { loading, errors, onSubmit } = useFormAction(
    (e) => createService(formData(e.currentTarget)),
    () => {
      revalidator.revalidate();
      onClose();
    },
  );

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{t("service.createTitle")}</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap="1rem">
            <TextField
              name="name"
              label={t("services.name")}
              required
              autoFocus
            />
            <TextField
              name="duration"
              label={t("services.duration")}
              type="number"
              required
            />
            <TextField
              name="color"
              label={t("services.color")}
              type="color"
              defaultValue="#c4afbd"
              required
            />
            <FormControl>
              <InputLabel>{t("services.calendars")}</InputLabel>
              <Select
                label={t("services.calendars")}
                name="calendar_ids"
                multiple
                defaultValue={[]}
              >
                {calendars.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormErrorList formErrors={errors} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("form.cancel")}</Button>
          <Button type="submit" disabled={loading}>
            {loading && <ButtonLoader />}
            {t("form.submit")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
