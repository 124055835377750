export function parseInt(s, def = null) {
  if (s == null) {
    return def;
  }

  const res = window.parseInt(s, 10);
  if (Number.isNaN(res)) {
    return def;
  }

  return res;
}
