import React from "react";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { TextField } from "@mui/material";

/**
 * @typedef {Object} Props
 * @property {Object} schema - The JSON Schema for the control
 * @property {Object} uischema - The UI Schema for customizing the control
 * @property {*} data - The current value of the data bound to the control
 * @property {Function} handleChange - Callback to update the form data
 * @property {string} path - The data path for the control
 */

/**
 * TextAreaRenderer
 * A custom renderer for displaying and editing multi-line text.
 *
 * @param {Props} props - The properties passed to the renderer.
 * @returns {React.ReactElement} The rendered text area field.
 */
const TextAreaRenderer = ({ uischema, data, handleChange, path }) => {
  // Default to empty string if data is undefined
  const notesText = data || "";

  /**
   * Handles text change events.
   *
   * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} event - The input change event.
   */
  const handleTextChange = (event) => {
    const updatedValue = event.target.value;

    if (typeof handleChange === "function") {
      handleChange(path, updatedValue);
    } else {
      console.error("handleChange is not a function!");
    }
  };

  return (
    <TextField
      label={uischema?.label || "Notes"}
      value={notesText} // Use the fallback value
      onChange={handleTextChange} // Trigger handleChange on input
      variant="outlined"
      fullWidth
      multiline
      rows={4}
      sx={{
        marginBottom: "10px",
        marginTop: "10px",
      }}
    />
  );
};

/**
 * Tester function to determine when the TextAreaRenderer should be used.
 *
 * @param {Object} uischema - The UI Schema for the control.
 * @returns {number} A rank indicating when this renderer should be used.
 */
export const textAreaTester = (uischema) => {
  return uischema?.scope === "#/properties/notes" ? 5 : -1;
};

// Wrap the renderer with JSONForms control props
export default withJsonFormsControlProps(TextAreaRenderer);
