import React from "react";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { TextField } from "@mui/material";

/**
 * @typedef {Object} Props
 * @property {Object} schema - The JSON Schema for the control
 * @property {Object} uischema - The UI Schema for customizing the control
 * @property {string} path - The data path for the control
 * @property {boolean} [visible=true] - Whether the control is visible
 * @property {boolean} [enabled=true] - Whether the control is enabled
 * @property {Function} handleChange - Callback to update the form data
 */

/**
 * FixedTextRenderer
 * A custom renderer to display a read-only field for fixed text with specific styles.
 *
 * @param {Props} props - The properties passed to the renderer.
 * @returns {React.ReactElement} The rendered fixed text field.
 */
const FixedTextRenderer = ({ schema }) => {
  const fixedText =
    schema?.text ||
    "Default fixed text: Please update the fixed text for this template.";

  return (
    <TextField
      label="Fixed Text"
      value={fixedText}
      variant="outlined"
      fullWidth
      multiline
      style={{
        pointerEvents: "none", // Disables interaction
        marginBottom: "1rem",
        marginTop: "2rem",
      }}
      rows={4}
      sx={{
        "& .MuiOutlinedInput-root": {
          backgroundColor: "inherit",
          "& fieldset": {
            border: "none", // Removes the border
          },
        },
      }}
    />
  );
};

/**
 * Tester function to determine when the FixedTextRenderer should be used.
 *
 * @param {Object} uischema - The UI Schema for the control.
 * @returns {number} A rank indicating when this renderer should be used.
 */
export const fixedTextTester = (uischema) => {
  // Check if the scope references the "fixedText" property
  return uischema?.scope === "#/properties/fixedText" ? 5 : -1;
};

// Wrap the renderer with JSONForms control props
export default withJsonFormsControlProps(FixedTextRenderer);
