import { TextField, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import * as f from "../utils/formatter";
import * as calUtil from "shared/src/calendar.mjs";
import * as d from "shared/src/date.mjs";
import AutocompleteSelect from "./AutocompleteSelect";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";

function toDayJs(v) {
  if (v == null) {
    return null;
  }

  return dayjs(v);
}

/**
 * @param {string} from
 * @param {string} to
 */
function dateRangeConstraints(from, to) {
  const minFrom = new Date();
  minFrom.setDate(minFrom.getDate() + 1);
  const minTo = from ?? minFrom;
  const maxFrom = to;

  return {
    from: {
      min: f.dateInput(minFrom),
      max: f.dateInput(maxFrom),
    },
    to: {
      min: f.dateInput(minTo),
    },
  };
}

/**
 * @param {string} from
 * @param {string} to
 */
function timeRangeConstraints(from, to) {
  const minTo = from;
  const maxFrom = to;

  return {
    from: {
      max: maxFrom,
    },
    to: {
      min: minTo,
    },
  };
}

/**
 * @param {Date} date
 * @param {string} timeString
 *
 * @returns {Date}
 */
function setTime(date, timeString) {
  const [h, m] = timeString.split(":").map((s) => parseInt(s, 10));

  const d = new Date(date.getTime());
  d.setHours(h);
  d.setMinutes(m);
  d.setSeconds(0);
  d.setMilliseconds(0);

  return d;
}

/**
 * @param {Date} d_to
 * @param {Date} d_from
 *
 * @returns {Date}
 */
function copyTime(d_to, d_from) {
  const d = new Date(d_to.getTime());
  d.setHours(d_from.getHours());
  d.setMinutes(d_from.getMinutes());
  d.setMilliseconds(d_from.getMilliseconds());

  return d;
}

/**
 * @param {Date} from
 * @param {Date} to
 */
function* dayRanges(from, to) {
  if (to < from) {
    return;
  }

  let currentFrom = new Date(from.getTime());
  while (currentFrom < to) {
    yield [currentFrom, copyTime(currentFrom, to)];

    const next = new Date(currentFrom.getTime());
    next.setDate(next.getDate() + 1);
    currentFrom = next;
  }
}

/**
 * @param {object} state
 * @param {string | null} state.from_date
 * @param {string | null} state.to_date
 * @param {string | null} state.from_time
 * @param {string | null} state.to_time
 * @param {{from: string, to: string}[]} state.value
 * @param {string[]} state.days
 */
function calculateValue(state) {
  if (state.from_date == null || state.to_date == null) {
    return [];
  }

  const fromDate = setTime(
    new Date(state.from_date),
    state.from_time ?? "00:00",
  );
  const toDate = setTime(new Date(state.to_date), state.to_time ?? "23:59");
  const allowedDays = new Set(state.days.map((day) => day.id));

  const dayFilter =
    allowedDays.size === 0
      ? (_) => true
      : ([from]) => allowedDays.has(calUtil.dateToDay(from));

  return Array.from(dayRanges(fromDate, toDate))
    .filter((val) => dayFilter(val))
    .map(([from, to]) => ({ from: d.toString(from), to: d.toString(to) }));
}

function useChangeDateRangeProp(setState, prop) {
  return React.useCallback(
    (e) => {
      setState((state) => {
        const v =
          e.currentTarget.value.length === 0 ? null : e.currentTarget.value;

        const stateWithProp = {
          ...state,
          [prop]: v,
        };

        return { ...stateWithProp, value: calculateValue(stateWithProp) };
      });
    },
    [setState, prop],
  );
}

function useChangeDateRangeProp2(setState, prop1, prop2) {
  return React.useCallback(
    (val) => {
      setState((state) => {
        const v1 = f.dateInput(val[0]?.$d);
        const v2 = f.dateInput(val[1]?.$d);

        const stateWithProp = {
          ...state,
          [prop1]: v1,
          [prop2]: v2,
        };

        return { ...stateWithProp, value: calculateValue(stateWithProp) };
      });
    },
    [setState, prop1, prop2],
  );
}

function initialDateRangeSelectorState() {
  return {
    from_date: null,
    to_date: null,
    from_time: null,
    to_time: null,
    days: [],
    value: [],
  };
}

/**
 * @param {object} props
 * @param {({from: string, to: string}[]) => void} onChange
 */
export default function DateRangeSelector({ onChange }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [state, setState] = React.useState(initialDateRangeSelectorState);

  React.useEffect(() => {
    onChange(state.value);
  }, [state.value]);

  const dateConstraints = dateRangeConstraints(state.from_date, state.to_date);
  const timeConstraints = timeRangeConstraints(state.from_time, state.to_time);
  const onDateChange = useChangeDateRangeProp2(
    setState,
    "from_date",
    "to_date",
  );

  const onFromTimeChange = useChangeDateRangeProp(setState, "from_time");
  const onToTimeChange = useChangeDateRangeProp(setState, "to_time");

  return (
    <fieldset
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        color: theme.palette.text.secondary,
        borderWidth: "1px",
        borderRadius: "4px",
        borderColor: "rgba(0, 0, 0, 0.23)",
      }}
    >
      <legend>Čas</legend>
      <DateRangePicker
        localeText={{
          start: t("appointment.from"),
          end: t("appointment.to"),
        }}
        slotProps={{ textField: { required: true } }}
        onChange={onDateChange}
        minDate={toDayJs(dateConstraints.from.min)}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
        }}
      >
        <TextField
          label={t("appointment.from")}
          type="time"
          onChange={onFromTimeChange}
          slotProps={{
            inputLabel: { shrink: true },
            htmlInput: timeConstraints.from,
          }}
          fullWidth
        />
        <TextField
          label={t("appointment.to")}
          type="time"
          onChange={onToTimeChange}
          slotProps={{
            inputLabel: { shrink: true },
            htmlInput: timeConstraints.to,
          }}
          fullWidth
        />
      </div>
      <AutocompleteSelect
        multiple
        label="Vybrané dny"
        options={calUtil.workingDays.map((day) => ({ id: day, name: day }))}
        onChange={(days) => {
          setState((state) => {
            const stateWithProp = { ...state, days };

            return { ...stateWithProp, value: calculateValue(stateWithProp) };
          });
        }}
      />
    </fieldset>
  );
}
