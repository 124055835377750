import { permutationsWithRepetition } from "shared/src/util.mjs";

const textSearchFields = ["first_name", "last_name"];

const numberSearchFields = ["birth_number", "phone"];

/**
 * @param {string[]} parts
 *
 * @return {string[]}
 */
function searchedFields(parts) {
  const allNumbers = parts.every((part) => part.match(/^\d+$/) != null);

  return allNumbers ? numberSearchFields : textSearchFields;
}

export function createClientFilter(term) {
  if (term.length === 0) {
    return;
  }

  const parts = term.split(" ");

  const filter = {
    or: permutationsWithRepetition(searchedFields(parts), parts.length).map(
      (fields) => {
        return {
          and: fields.map((f, i) => {
            return { [f]: { contains: parts[i] } };
          }),
        };
      },
    ),
  };

  return filter;
}
