import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  IconButton,
  Typography,
  Box,
  TablePagination,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MoreVertIcon from "@mui/icons-material/MoreVert";

/**
 * @typedef {Object} Document
 * @property {string} name
 * @property {string} type
 */

/**
 * @typedef {Object} DocumentsTableProps
 * @property {Document[]} documents
 */

/**
 * @param {DocumentsTableProps} props
 */
const DocumentsTable = ({ documents }) => {
  const { t } = useTranslation();

  return (
    <Box width="100%" padding={2}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox />
              </TableCell>
              <TableCell>{t("client.documents.name")}</TableCell>
              <TableCell>{t("client.documents.created")}</TableCell>
              <TableCell>{t("client.documents.sent")}</TableCell>
              <TableCell>{t("client.documents.signed")}</TableCell>
              <TableCell>{t("client.documents.payment")}</TableCell>
              <TableCell>{t("client.documents.approvedBy")}</TableCell>
              <TableCell>{t("client.documents.rejected")}</TableCell>
              <TableCell align="center">
                {t("client.documents.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((doc, index) => (
              <TableRow key={index}>
                <TableCell padding="checkbox">
                  <Checkbox />
                </TableCell>
                <TableCell>{doc.name}</TableCell>
                <TableCell>{doc.created}</TableCell>
                <TableCell>{doc.sent}</TableCell>
                <TableCell>{doc.signed ? t("yes") : t("no")}</TableCell>
                <TableCell>{doc.payment ? t("yes") : t("no")}</TableCell>
                <TableCell>
                  {doc.approvedBy && (
                    <Typography variant="body2">
                      {doc.approvedBy} <br /> {doc.approvedDate}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>{doc.rejected ? t("yes") : t("no")}</TableCell>
                <TableCell align="center">
                  <IconButton>
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <TablePagination
            component="div"
            count={documents.length}
            rowsPerPage={3}
            page={0}
            onPageChange={() => {}}
            onRowsPerPageChange={() => {}}
          />
        </Box>
      </TableContainer>
    </Box>
  );
};

export default DocumentsTable;
