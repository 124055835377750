import React from "react";
import { Avatar, Box, Menu, MenuItem, Typography } from "@mui/material";
import { useAuthData } from "../context/AuthenticationTokenContext";
import * as auth from "../auth";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/pro-solid-svg-icons";
import { styled } from "@mui/material/styles";

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 180,
    color: theme.palette.text.primary,
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
  },
}));

const StyledMenuItem = styled(MenuItem)({
  padding: "12px 16px",
  justifyContent: "center",
});

const LogoutMenuItem = styled(StyledMenuItem)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const MenuItemContent = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 16,
  width: "100%",
});

export function UserMenu() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { first_name, last_name } = useAuthData();

  const fullName = `${first_name} ${last_name}`;
  const initials =
    `${first_name?.[0] || ""}${last_name?.[0] || ""}`.toUpperCase();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    auth.logout();
    handleClose();
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        onClick={handleMenu}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          padding: "6px",
          cursor: "pointer",
          borderRadius: 1,
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
        }}
      >
        <Avatar
          sx={{
            width: 32,
            height: 32,
            backgroundColor: "#E3F2FD",
            color: "#2196F3",
            fontSize: "0.875rem",
            fontWeight: 500,
            border: "2px solid #90CAF9",
          }}
        >
          {initials}
        </Avatar>
        <Typography
          variant="body2"
          color="text.primary"
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          {fullName}
        </Typography>
      </Box>
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <LogoutMenuItem onClick={handleLogout}>
          <MenuItemContent>
            <FontAwesomeIcon icon={faPowerOff} />
            <Typography>{t("userMenu.logout")}</Typography>
          </MenuItemContent>
        </LogoutMenuItem>
      </StyledMenu>
    </Box>
  );
}
