import React from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
} from "@mui/material";
import { useRouteError, isRouteErrorResponse } from "react-router";
import * as logging from "../logging.js";
import { useTranslation } from "react-i18next";
import Link from "./Link.jsx";

function Layout({ children }) {
  const { t } = useTranslation();

  return (
    <Container
      sx={{ height: "100%", display: "flex", justifyContent: "center" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper display="inline-block" sx={{ padding: 4 }}>
          {children}
          <List>
            <ListItem sx={{ justifyContent: "center" }}>
              <Link>{t("error.reload")}</Link>
            </ListItem>
            <ListItem sx={{ justifyContent: "center" }}>
              <Link to="/">{t("error.home")}</Link>
            </ListItem>
          </List>
        </Paper>
      </Box>
    </Container>
  );
}

export default function ErrorElement() {
  const { t } = useTranslation();
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    console.error(error);

    return (
      <Layout>
        <Typography variant="h1">
          {error.status} {error.statusText}
        </Typography>
      </Layout>
    );
  }

  logging.error(error);

  return (
    <Layout>
      <Typography variant="h1">{t("error.general")}</Typography>
    </Layout>
  );
}
