import React from "react";
import { Chip } from "@mui/material";

const Tag = ({ label, color = "default" }) => {
  return (
    <Chip
      label={label}
      sx={{
        backgroundColor: color,
        color: "#fff",
        marginRight: 1,
        marginBottom: 1,
      }}
    />
  );
};

export default Tag;
