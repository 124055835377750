import React from "react";
import { createBrowserRouter } from "react-router";
import Homepage from "./page/Homepage";
import Calendar, { loader as calendarLoader } from "./page/Calendar";
import Notifications from "./page/Notifications";
import FCalendar, { loader as fcalendarLoader } from "./page/FCalendar";
import FEmployees, { loader as femployeesLoader } from "./page/FEmployees";
import Client, { loader as clientLoader } from "./page/Client";
import Clients, { loader as clientsLoader } from "./page/Clients";
import Services, { loader as servicesLoader } from "./page/Services";
import Reception, { loader as receptionLoader } from "./page/Reception";
import Locations, { loader as locationsLoader } from "./page/Locations";
import LocationEdit, {
  loader as locationEditLoadeer,
  action as locationEditAction,
} from "./page/LocationEdit";
import Calendars, { loader as calendarsLoader } from "./page/Calendars";
import CalendarEdit, {
  loader as calendarEditLoader,
  action as calendarEditAction,
} from "./page/CalendarEdit";
import ErrorElement from "./components/ErrorElement";
import ProtectedRoute from "./components/ProtectedRoute";
import LoginPage from "./page/Login";

function shouldRevalidate({ actionResult }) {
  if ((actionResult?.errors?.length ?? 0) > 0) {
    return false;
  }

  return true;
}

export const router = createBrowserRouter(
  [
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <Homepage />
        </ProtectedRoute>
      ),
      errorElement: <ErrorElement />,
    },
    {
      path: "/calendar",
      element: (
        <ProtectedRoute>
          <Calendar />
        </ProtectedRoute>
      ),
      errorElement: <ErrorElement />,
      loader: calendarLoader,
    },
    {
      path: "/fcalendar",
      element: (
        <ProtectedRoute>
          <FCalendar />
        </ProtectedRoute>
      ),
      errorElement: <ErrorElement />,
      loader: fcalendarLoader,
    },
    {
      path: "/femployees",
      element: (
        <ProtectedRoute>
          <FEmployees />
        </ProtectedRoute>
      ),
      errorElement: <ErrorElement />,
      loader: femployeesLoader,
    },
    {
      path: "/calendars",
      element: (
        <ProtectedRoute>
          <Calendars />
        </ProtectedRoute>
      ),
      errorElement: <ErrorElement />,
      loader: calendarsLoader,
    },
    {
      path: "/calendars/:id/edit",
      element: (
        <ProtectedRoute>
          <CalendarEdit />
        </ProtectedRoute>
      ),
      errorElement: <ErrorElement />,
      loader: calendarEditLoader,
      action: calendarEditAction,
      shouldRevalidate,
    },
    {
      path: "/clients",
      element: (
        <ProtectedRoute>
          <Clients />
        </ProtectedRoute>
      ),
      errorElement: <ErrorElement />,
      loader: clientsLoader,
    },
    {
      path: "/clients/:id",
      element: (
        <ProtectedRoute>
          <Client />
        </ProtectedRoute>
      ),
      errorElement: <ErrorElement />,
      loader: clientLoader,
    },
    {
      path: "/reception",
      element: (
        <ProtectedRoute>
          <Reception />
        </ProtectedRoute>
      ),
      errorElement: <ErrorElement />,
      loader: receptionLoader,
    },
    {
      path: "/services",
      element: (
        <ProtectedRoute>
          <Services />
        </ProtectedRoute>
      ),
      errorElement: <ErrorElement />,
      loader: servicesLoader,
    },
    {
      path: "/locations",
      element: (
        <ProtectedRoute>
          <Locations />
        </ProtectedRoute>
      ),
      errorElement: <ErrorElement />,
      loader: locationsLoader,
    },
    {
      path: "/locations/:id/edit",
      element: (
        <ProtectedRoute>
          <LocationEdit />
        </ProtectedRoute>
      ),
      errorElement: <ErrorElement />,
      loader: locationEditLoadeer,
      action: locationEditAction,
      shouldRevalidate,
    },
    {
      path: "/login",
      element: <LoginPage />,
      errorElement: <ErrorElement />,
    },
    {
      path: "/notifications",
      element: (
        <ProtectedRoute>
          <Notifications />
        </ProtectedRoute>
      ),
      errorElement: <ErrorElement />,
    },
  ],
  {
    future: {
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  },
);
