import React from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";

/**
 * @typedef AutocompleteSelectOption
 * @property {string} id
 * @property {string} name
 *
 * @param {object} props
 * @param {AutocompleteSelectOption[]} props.options
 * @param {boolean} [props.loading]
 * @param {string} props.label
 * @param {(option: AutocompleteSelectOption) => void} [props.onChange]
 * @param {string} [props.name]
 * @param {AutocompleteSelectOption} [props.value]
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.readOnly]
 * @param {boolean} [props.required]
 * @param {boolean} [props.multiple]
 * @param {string} [props.size]
 */
export default function AutocompleteSelect(props) {
  const {
    options,
    loading,
    label,
    onChange,
    name,
    disabled,
    value: externalValue,
    readOnly,
    required,
    multiple,
    size,
  } = props;
  const [internalValue, setInternalValue] = React.useState(
    multiple ? [] : null,
  );
  const value = externalValue === undefined ? internalValue : externalValue;

  return (
    <>
      {name && <input type="hidden" name={name} value={value?.id ?? ""} />}
      <Autocomplete
        size={size}
        multiple={multiple}
        autoHighlight={true}
        disableCloseOnSelect={multiple}
        sx={{ minWidth: "400px" }}
        disabled={disabled}
        value={externalValue}
        loading={loading}
        options={options}
        getOptionLabel={(opt) => opt.name}
        getOptionKey={(opt) => opt.id}
        readOnly={readOnly}
        isOptionEqualToValue={(opt, val) => opt.id === val.id}
        renderInput={(params) => (
          <TextField
            label={label}
            required={required}
            {...params}
            slotProps={{
              input: {
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              },
            }}
          />
        )}
        onChange={(_, value) => {
          setInternalValue(value);
          onChange?.(value);
        }}
      />
    </>
  );
}
