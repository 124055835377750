import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Grid2, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as api from "../../services/api";
import NotesForReceptionForm from "./NotesForReceptionForm.jsx";
import AppointmentServices from "./AppointmentServices.jsx";
import { useWizardContext } from "./AppointmentWizardContext";

const AppointmentNotesTabContent = () => {
  const { t } = useTranslation(["translation"]);
  const [services, setServices] = useState([]);
  const [page] = useState({ limit: 60, offset: 0 });
  const { nurseNotes } = useWizardContext();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const fetchServices = async () => {
    try {
      const result = await api.loadServicesWithTemplates({
        page,
        filter: {
          visible: { eq: true },
        },
      });
      setServices(result.data.services.data);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    fetchServices();
  }, [page]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    console.log("Form sent to receptionist:");
  };

  console.log(services);

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Typography variant="h4" sx={{ marginBottom: 3 }}>
        {t("appointmentNotes.title")}
      </Typography>

      <NotesForReceptionForm services={services} initialNotes={nurseNotes} />

      <Divider sx={{ marginTop: 4, marginBottom: 4 }} />

      <form onSubmit={handleSubmit} id="appointmentNotesForm">
        <AppointmentServices
          services={services}
          formSubmitted={formSubmitted}
        />
      </form>

      <Grid2
        container
        spacing={3}
        alignItems="center"
        sx={{ justifyContent: "flex-end", marginTop: 2 }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{ alignSelf: "flex-end" }}
          form="appointmentNotesForm"
          type="submit"
        >
          {t("appointmentNotes.sendToReceptionist")}
        </Button>
      </Grid2>
    </Box>
  );
};

export default AppointmentNotesTabContent;
