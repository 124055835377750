import React from "react";
import * as api from "../services/api";
import { useLoaderData } from "react-router";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import * as f from "../utils/formatter";

export async function loader() {
  return api.loadFlooreManagerEmployees();
}
export default function FEmployees() {
  const { t } = useTranslation();
  const loaderData = useLoaderData();
  const employeesConnection = loaderData.data.employees;

  return (
    <Card>
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("employees.fullName")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employeesConnection.data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{f.fullName(row)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
