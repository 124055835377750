import React from "react";
import NotificationItem from "./NotificationItem";

/**
 * NotificationsList component renders a list of notification items.
 * If there are no notifications, it displays a fallback message.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Array<Object>} props.notifications - The list of notifications to display.
 * @param {function} props.handleItemClick - Function to handle when a notification item is clicked.
 * @param {string} props.emptyMessage - Message to display when there are no notifications.
 * @returns {JSX.Element}
 */

export default function NotificationsList({
  notifications,
  handleItemClick,
  emptyMessage,
}) {
  // Render a fallback message if there are no notifications
  if (!notifications || notifications.length === 0) {
    return <div>{emptyMessage}</div>;
  }

  return (
    <>
      {notifications.map((item) => (
        <NotificationItem
          key={item.id}
          item={item}
          handleItemClick={handleItemClick}
        />
      ))}
    </>
  );
}
