import React, { useContext, createContext } from "react";
import { jwtDecode } from "jwt-decode";
import { authenticationTokenExpiresIn, getAuthenticationToken } from "../auth";

const AuthenticationTokenContext = createContext();

export function useAuthenticationToken() {
  const context = useContext(AuthenticationTokenContext);
  return [context.token, context.setToken];
}

export function useAuthData() {
  const context = useContext(AuthenticationTokenContext);
  return {
    roles: context.roles,
    first_name: context.first_name,
    last_name: context.last_name,
    username: context.username,
  };
}

export function AuthenticationTokenProvider({ children }) {
  const [token, setToken] = React.useState(getAuthenticationToken());
  const [decodedToken, setDecodedToken] = React.useState(() => {
    const initialToken = getAuthenticationToken();
    return initialToken ? jwtDecode(initialToken) : null;
  });

  React.useEffect(() => {
    setDecodedToken(token ? jwtDecode(token) : null);
  }, [token]);

  const value = React.useMemo(
    () => ({
      token,
      setToken,
      roles: decodedToken?.roles || {},
      first_name: decodedToken?.first_name,
      last_name: decodedToken?.last_name,
      username: decodedToken?.username,
    }),
    [token, decodedToken],
  );

  React.useEffect(
    function removeExpiredToken() {
      if (token == null) return;

      const timeoutId = setTimeout(() => {
        setToken(null);
      }, authenticationTokenExpiresIn(token));

      return () => clearTimeout(timeoutId);
    },
    [token],
  );

  return (
    <AuthenticationTokenContext.Provider value={value}>
      {children}
    </AuthenticationTokenContext.Provider>
  );
}

export { AuthenticationTokenContext };
