import React, { useEffect, useState } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import { Typography, Box, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { loadClientActivityLogs } from "../../../services/api";

/**
 * @typedef {Object} AppointmentHistoryCardProps
 * @property {string} id
 */

/**
 * @param {AppointmentHistoryCardProps} props
 */
const ClientActivityLogTimeline = ({ id: clientId }) => {
  const { t, i18n } = useTranslation();
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchActivityLog() {
      try {
        const response = await loadClientActivityLogs({ client_id: clientId });
        setActivities(response.data?.clientActivityLogs || []);
      } catch (err) {
        setError(err.message || "Failed to load activity log.");
      } finally {
        setLoading(false);
      }
    }

    fetchActivityLog();
  }, [clientId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(i18n.language, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString(i18n.language, {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const renderContent = (activity) => {
    switch (activity.activity_type) {
      case "appointment":
        return (
          <>
            <Typography
              variant="h6"
              color="primary"
              sx={{ fontWeight: "bold", marginBottom: 1 }}
            >
              {t("client.appointment")}
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>
              {activity.description || t("client.noDescription")}
            </Typography>
            {activity.employee_name && (
              <Typography variant="body2" sx={{ marginBottom: 1 }}>
                {t("client.performedBy")}: {activity.employee_name}
              </Typography>
            )}
          </>
        );
      case "medical_report":
        return (
          <>
            <Typography
              variant="h6"
              color="primary"
              sx={{ fontWeight: "bold", marginBottom: 1 }}
            >
              {t("client.medicalReport")}
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>
              {activity.medical_report_text || t("client.noReportText")}
            </Typography>
            {activity.employee_name && (
              <Typography variant="body2" sx={{ marginBottom: 1 }}>
                {t("client.createdBy")}: {activity.employee_name}
              </Typography>
            )}
          </>
        );
      default:
        return (
          <>
            <Typography
              variant="h6"
              color="primary"
              sx={{ fontWeight: "bold", marginBottom: 1 }}
            >
              {t("client.unknownActivity")}
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>
              {activity.description || t("client.noDescription")}
            </Typography>
          </>
        );
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Typography color="error">
        {t("error.general")}: {error}
      </Typography>
    );
  }

  return (
    <Box sx={{ maxWidth: 750 }}>
      {activities && activities.length > 0 ? (
        <Timeline>
          {Array.isArray(activities) &&
            activities.map((activity, index) => (
              <TimelineItem key={activity.id}>
                <TimelineOppositeContent
                  sx={{ m: "auto 0", textAlign: "left" }}
                  variant="body2"
                  color="text.secondary"
                >
                  <Typography variant="h4" color="primary">
                    {formatDate(activity.created_at)}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "small", color: "grey" }}
                  >
                    {formatTime(activity.created_at)}
                  </Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                  <TimelineDot
                    variant="outlined"
                    sx={{
                      borderColor: "primary.main",
                      borderWidth: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: 8,
                        height: 8,
                        backgroundColor: "primary.main",
                        borderRadius: "50%",
                      }}
                    />
                  </TimelineDot>

                  {index < activities.length - 1 && (
                    <TimelineConnector
                      sx={{
                        height: "40px",
                        backgroundColor: "grey.500",
                        width: "2px",
                      }}
                    />
                  )}
                </TimelineSeparator>

                <TimelineContent sx={{ py: "12px", px: 2, maxWidth: "450px" }}>
                  <Box>{renderContent(activity)}</Box>
                </TimelineContent>
              </TimelineItem>
            ))}
        </Timeline>
      ) : (
        <Typography variant="body1" color="text.secondary">
          {t("client.noActivityLogs")}
        </Typography>
      )}
    </Box>
  );
};

export default ClientActivityLogTimeline;
