import React from "react";
import { Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

/**
 * @typedef {Object} Client
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} email
 */

/**
 * @typedef {Object} ClientInfoCardProps
 * @property {Client} client
 */

/**
 * @param {ClientInfoCardProps} props
 */
const ClientInfoCard = ({ client }) => {
  const { t } = useTranslation();
  const fullAddress = `${client.street || ""}, ${client.city || ""}, ${client.post_code || ""}`;

  const cardStyle = {
    padding: 2,
    marginBottom: 2,
    maxWidth: 300,
    minHeight: 200,
  };

  return (
    <Paper elevation={3} sx={cardStyle}>
      <Typography variant="h6">{t("client.info.card.title")}</Typography>
      <Typography variant="body1">
        <strong>{t("client.info.name")}</strong> {client.first_name}{" "}
        {client.last_name}
      </Typography>
      <Typography variant="body1">
        <strong>{t("client.info.email")}</strong> {client.email}
      </Typography>
      <Typography variant="body1">
        <strong>{t("client.info.phone")}</strong> {client.phone}
      </Typography>
      <Typography variant="body1">
        <strong>{t("client.info.address")}</strong> {fullAddress}
      </Typography>
    </Paper>
  );
};

export default ClientInfoCard;
