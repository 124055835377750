import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { loadNotifications, markNotificationsRead } from "../services/api";
import { useAuthenticationToken } from "./AuthenticationTokenContext";

const NotificationsContext = createContext(null);

export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error(
      "useNotifications must be used within a NotificationsProvider",
    );
  }
  return context;
};

const NOTIFICATIONS_PER_PAGE = 10;

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const initRef = useRef(false);
  const fetchInProgressRef = useRef(false);
  const [authToken] = useAuthenticationToken();

  const fetchNotifications = useCallback(
    async (page = { offset: 0, limit: NOTIFICATIONS_PER_PAGE }) => {
      if (authToken == null || fetchInProgressRef.current) {
        return;
      }

      try {
        fetchInProgressRef.current = true;
        setLoading(true);

        const response = await loadNotifications({
          page: {
            limit: page.limit || NOTIFICATIONS_PER_PAGE,
            offset: page.offset || 0,
          },
          filter: {},
        });

        if (response.errors) {
          console.error("GraphQL Errors:", response.errors);
          setError(new Error(response.errors[0]?.message || "GraphQL Error"));
          return;
        }

        const notificationsData = response.data?.notifications;
        if (notificationsData) {
          setNotifications((prev) =>
            page.offset === 0
              ? notificationsData.data
              : [...prev, ...notificationsData.data],
          );
          setTotalCount(notificationsData.count);
        } else {
          console.error("Unexpected response structure:", response);
          setError(new Error("Invalid response format"));
        }
      } catch (err) {
        console.error("Failed to load notifications:", err);
        setError(err);
      } finally {
        setLoading(false);
        fetchInProgressRef.current = false;
      }
    },
    [authToken],
  );

  useEffect(() => {
    if (authToken != null && !initRef.current) {
      initRef.current = true;
      fetchNotifications();
    }

    return () => {
      initRef.current = false;
      fetchInProgressRef.current = false;
    };
  }, [authToken]);

  const handleItemClick = useCallback(
    async (id) => {
      if (authToken == null) return;

      try {
        const response = await markNotificationsRead({
          ids: [id],
          isRead: true,
        });

        if (response.errors) {
          console.error("GraphQL Errors:", response.errors);
          return;
        }

        setNotifications((prev) =>
          prev.map((notification) =>
            notification.id === id
              ? { ...notification, isRead: true }
              : notification,
          ),
        );
      } catch (err) {
        console.error("Failed to mark notification as read:", err);
      }
    },
    [authToken],
  );

  const markAllAsRead = useCallback(
    async (notificationsToUpdate, allRead) => {
      if (authToken == null) return;

      try {
        const ids = notificationsToUpdate.map((n) => n.id);
        const response = await markNotificationsRead({
          ids,
          isRead: !allRead,
        });

        if (response.errors) {
          console.error("GraphQL Errors:", response.errors);
          return;
        }

        setNotifications((prev) =>
          prev.map((notification) =>
            ids.includes(notification.id)
              ? { ...notification, isRead: !allRead }
              : notification,
          ),
        );
      } catch (err) {
        console.error("Failed to mark all as read:", err);
      }
    },
    [authToken],
  );

  const loadMore = useCallback(() => {
    if (loading || notifications.length >= totalCount) return;

    return fetchNotifications({
      offset: notifications.length,
      limit: NOTIFICATIONS_PER_PAGE,
    });
  }, [loading, notifications.length, totalCount, fetchNotifications]);

  const contextValue = {
    notifications,
    loading,
    error,
    totalCount,
    handleItemClick,
    markAllAsRead,
    fetchNotifications,
    loadMore,
    hasMore: notifications.length < totalCount,
    unreadCount: notifications.filter((n) => !n.isRead).length,
    isAuthenticated: () => authToken != null,
  };

  return (
    <NotificationsContext.Provider value={contextValue}>
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
