import React from "react";
import { TablePagination } from "../components/table";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TextField,
  InputAdornment,
  Card,
  CardContent,
} from "@mui/material";
import * as api from "../services/api";
import { useLoaderData, useSearchParams } from "react-router";
import { useTranslation } from "react-i18next";
import * as qs from "../qs";
import * as p from "../parser";
import { createClientFilter } from "../client";
import { debounce } from "shared/src/util.mjs";
import { DEBOUNCE_INPUT } from "../constants";
import { useNavigate } from "../url";

const defaults = [
  [["page", "limit"], 10],
  [["page", "offset"], 0],
];

const parsers = [
  [["page", "limit"], (val) => p.parseInt(val, 10)],
  [["page", "offset"], (val) => p.parseInt(val, 0)],
];

const filters = [[["filter", "search"], (v) => v.length === 0]];

function graphqlArgs(request) {
  const parsedArgs = qs.parse(new URL(request.url).searchParams.toString(), {
    defaults: defaults,
    parsers: parsers,
    filters: filters,
  });

  const search = parsedArgs.filter?.search;
  if (search != null) {
    const clientFilter = createClientFilter(search);
    if (clientFilter == null) {
      return null;
    }

    delete parsedArgs.filter.search;
    parsedArgs.filter = {
      ...parsedArgs.filter,
      or: [...(parsedArgs.filter.or ?? []), clientFilter],
    };
  }

  return parsedArgs;
}

export async function loader({ request }) {
  const args = graphqlArgs(request);
  if (args == null) {
    return { clients: { data: { clients: { data: [] } } } };
  }

  return { clients: await api.loadClients(args) };
}

function useChangeQuery() {
  const [_, setSearchParams] = useSearchParams();
  const changeQuery = React.useCallback(
    (newValues) => {
      setSearchParams((prev) => ({
        ...Object.fromEntries(prev),
        ...newValues,
      }));
    },
    [setSearchParams],
  );

  return changeQuery;
}

function useSearch() {
  const changeQuery = useChangeQuery();

  return React.useCallback(
    debounce(
      (term) =>
        changeQuery({
          "page[offset]": 0,
          "filter[search]": term,
        }),
      DEBOUNCE_INPUT,
    ),
    [changeQuery],
  );
}

export default function Clients() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loaderData = useLoaderData();
  const clientsConnection = loaderData.clients.data.clients;
  const search = useSearch();
  const [searchParams] = useSearchParams();

  return (
    <Card>
      <CardContent>
        <TextField
          type="search"
          defaultValue={searchParams.get("filter[search]") ?? ""}
          onInput={(e) => search(e.target.value)}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">&#128270;</InputAdornment>
              ),
            },
          }}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("clients.firstName")}</TableCell>
              <TableCell>{t("clients.lastName")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientsConnection.data.map((row) => (
              <TableRow
                key={row.id}
                hover={true}
                sx={{ cursor: "pointer" }}
                onClick={() => navigate(`/clients/${row.id}`)}
              >
                <TableCell>{row.first_name}</TableCell>
                <TableCell>{row.last_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination connection={clientsConnection} />
            </TableRow>
          </TableFooter>
        </Table>
      </CardContent>
    </Card>
  );
}
