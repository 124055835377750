import { useTranslation } from "react-i18next";
import { useRevalidator } from "react-router";
import React, { useState } from "react";
import { formData, FormErrorList, useFormAction } from "../../form.jsx";
import {
  createServiceTemplate,
  patchServiceTemplate,
} from "../../services/api.js";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import ButtonLoader from "../ButtonLoader.jsx";

const validateJSONObject = (e, setFormErrors, setTemplatePayload) => {
  const fieldName = e.target.name;
  try {
    const o = JSON.parse(e.target.value);

    if (o && typeof o === "object") {
      setFormErrors((currentValue) => ({
        ...currentValue,
        [fieldName]: "",
      }));

      setTemplatePayload((currentValue) => ({
        ...currentValue,
        [e.target.name]: JSON.stringify(o),
      }));

      return o;
    }
  } catch (_e) {
    setFormErrors((currentValue) => ({
      ...currentValue,
      [fieldName]: "Špatný formát JSON",
    }));
  }

  return false;
};

export const ServiceTemplateDialog = ({
  setEditServiceTemplate,
  editServiceTemplate,
}) => {
  const { t } = useTranslation();
  const revalidator = useRevalidator();
  const isUpdate = editServiceTemplate.templates?.length > 0;
  const [templatePayload, setTemplatePayload] = useState({
    schema: isUpdate
      ? editServiceTemplate.templates[0].template?.schema
      : JSON.stringify({}),
    uischema: isUpdate
      ? editServiceTemplate.templates[0].template?.uischema
      : JSON.stringify({}),
  });
  const [formErrors, setFormErrors] = useState({
    schema: "",
    uischema: "",
  });

  const handleChange = (e) => {
    setTemplatePayload((currentValue) => ({
      ...currentValue,
      [e.target.name]: e.target.value,
    }));
  };
  const formAction = isUpdate ? patchServiceTemplate : createServiceTemplate;
  const { loading, errors, onSubmit } = useFormAction(
    (e) => {
      const formInput = formData(e.currentTarget);
      const actionPayload = {
        ...{
          service_id: formInput.serviceId,
          version: "1.0",
          template: templatePayload,
        },
        ...(isUpdate && { id: editServiceTemplate.templates[0].id }),
      };
      return formAction(actionPayload);
    },
    () => {
      revalidator.revalidate();
      setEditServiceTemplate(null);
    },
  );

  return (
    <Dialog
      open={true}
      onClose={() => setEditServiceTemplate(null)}
      PaperProps={{
        component: "form",
        onSubmit: onSubmit,
        sx: { minWidth: "600px" },
      }}
    >
      <DialogTitle>
        {isUpdate
          ? t("service.editTemplateTitle")
          : t("service.createTemplateTitle")}
      </DialogTitle>

      <input type="hidden" name="serviceId" value={editServiceTemplate.id} />
      {isUpdate && <input type="hidden" name="id" value="1.0" />}
      <DialogContent>
        <Box display="flex" flexDirection="column" gap="1rem">
          <TextField
            sx={{ my: 1, width: "100%" }}
            name="schema"
            id="schema"
            label={t("services.templateDialog.schemaLabel")}
            onChange={handleChange}
            value={templatePayload.schema}
            multiline
            rows="4"
            slotProps={{ input: { sx: { fontFamily: "monospace" } } }}
            onBlur={(e) => {
              validateJSONObject(e, setFormErrors, setTemplatePayload);
            }}
            error={formErrors.schema !== ""}
            helperText={formErrors.schema}
          />
          <TextField
            sx={{ my: 1, width: "100%" }}
            name="uischema"
            id="uischema"
            label={t("services.templateDialog.uiSchemaLabel")}
            onChange={handleChange}
            value={templatePayload.uischema}
            multiline
            rows="4"
            slotProps={{ input: { sx: { fontFamily: "monospace" } } }}
            onBlur={(e) => {
              validateJSONObject(e, setFormErrors, setTemplatePayload);
            }}
            error={formErrors.uischema !== ""}
            helperText={formErrors.uischema}
          />
          <FormErrorList formErrors={errors} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setEditServiceTemplate(null)}>
          {t("form.cancel")}
        </Button>
        <Button type="submit" disabled={loading}>
          {loading && <ButtonLoader />}
          {t("form.submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
