import React from "react";
import { TablePagination as MuiTablePagination } from "@mui/material";
import { useSearchParams } from "react-router";
import { useTranslation } from "react-i18next";

function useChangeQuery() {
  const [_, setSearchParams] = useSearchParams();
  const changeQuery = React.useCallback(
    (newValues) => {
      setSearchParams((prev) => ({
        ...Object.fromEntries(prev),
        ...newValues,
      }));
    },
    [setSearchParams],
  );

  return changeQuery;
}

/**
 * @param {object} props
 * @param {object} props.connection
 * @param {number} props.connection.count
 * @param {object} props.connection.page
 * @param {number} props.connection.page.offset
 * @param {number} props.connection.page.limit
 */
export function TablePagination({ connection }) {
  const { t } = useTranslation();
  const changeQuery = useChangeQuery();

  if (connection.page == null) {
    return null;
  }

  return (
    <MuiTablePagination
      count={connection.count}
      rowsPerPage={connection.page.limit}
      page={connection.page.offset / connection.page.limit}
      onPageChange={(_, page) => {
        changeQuery({
          "page[offset]": page * connection.page.limit,
        });
      }}
      onRowsPerPageChange={(e) => {
        changeQuery({
          "page[offset]": 0,
          "page[limit]": e.target.value,
        });
      }}
      labelRowsPerPage={t("pagination.rowsPerPage")}
      labelDisplayedRows={({ from, to, count }) =>
        t("pagination.displayedRows", { from, to, count })
      }
    />
  );
}
