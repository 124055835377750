import { useCallback } from "react";
import { useWizardContext } from "../AppointmentWizardContext";

export const WIZARD_STEPS = {
  PREPARATION: 0,
  COMPLETION: 1,
  RECEPTION_NOTES: 2,
  PROCEDURE_NOTES: 3,
  CHAIR_CLEANING: 4,
};

export const useWizardSteps = () => {
  const { activeStep, setActiveStep } = useWizardContext();

  const getStepConfig = useCallback((step) => {
    const configs = {
      [WIZARD_STEPS.PREPARATION]: {
        label: "Preparation",
        nextLabel: "Start Appointment",
        canProgress: true,
      },
      [WIZARD_STEPS.COMPLETION]: {
        label: "Completion",
        nextLabel: "Complete Treatment",
        canProgress: true,
      },
      [WIZARD_STEPS.RECEPTION_NOTES]: {
        label: "Reception Notes",
        nextLabel: "Finish Appointment",
        canProgress: true,
      },
      [WIZARD_STEPS.PROCEDURE_NOTES]: {
        label: "Procedure Notes",
        nextLabel: "Continue",
        canProgress: true,
      },
      [WIZARD_STEPS.CHAIR_CLEANING]: {
        label: "Chair Cleaning",
        nextLabel: "Finish",
        canProgress: false,
      },
    };

    return configs[step] || configs[WIZARD_STEPS.PREPARATION];
  }, []);

  const handleProgressStep = useCallback(() => {
    const currentConfig = getStepConfig(activeStep);

    if (currentConfig.canProgress && activeStep < WIZARD_STEPS.CHAIR_CLEANING) {
      setActiveStep((prev) => prev + 1);
    }
  }, [activeStep, setActiveStep, getStepConfig]);

  const isStepCompleted = useCallback(
    (step) => {
      return step < activeStep;
    },
    [activeStep],
  );

  const isStepActive = useCallback(
    (step) => {
      return step === activeStep;
    },
    [activeStep],
  );

  const getStepButtonLabel = useCallback(() => {
    const config = getStepConfig(activeStep);
    return config.nextLabel;
  }, [activeStep, getStepConfig]);

  return {
    activeStep,
    handleProgressStep,
    isStepCompleted,
    isStepActive,
    getStepButtonLabel,
    getStepConfig,
  };
};
