import * as qs from "qs";
import {
  assocInIfUnset,
  dissocInIf,
  updateInIfExists,
} from "shared/src/util.mjs";

function setDefaults(m, defaults) {
  return defaults.reduce((m, [ks, v]) => assocInIfUnset(m, ks, v), m);
}

function parseObj(m, parsers) {
  return parsers.reduce((m, [ks, f]) => updateInIfExists(m, ks, f), m);
}

function removeMatching(m, preds) {
  return preds.reduce((m, [ks, f]) => dissocInIf(m, ks, f), m);
}

/**
 * @param {string} s
 * @param {object} opts
 * @param {[string[], any][]} opts.defaults
 * @param {[string[], (val: any) => any][]} [opts.filters]
 * @param {[string[], (val: any) => boolean][]} opts.removals
 */
export function parse(s, { defaults, parsers, filters = [] }) {
  const args = qs.parse(s);
  return removeMatching(
    setDefaults(parseObj(args, parsers), defaults),
    filters,
  );
}
