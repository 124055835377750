import { config } from "../config/config";
import { getAuthenticationToken } from "../auth";

/**
 * @typedef {object} Config
 * @property {WebSocket} [socket]
 * @property {boolean} running
 * @property {Set<string>} subscriptions
 */

/**
 * @returns {Config}
 */
function getMutableConfig() {
  if (window?.app?.ws == null) {
    window.app = window.app ?? {};
    window.app.ws = window.app.ws ?? {
      subscriptions: new Set(),
      socket: null,
      running: false,
    };
  }

  return window.app.ws;
}

export function subscribe(f) {
  getMutableConfig().subscriptions.add(f);
}

export function unsubscribe(f) {
  getMutableConfig().subscriptions.delete(f);
}

function onMessage(msg) {
  getMutableConfig().subscriptions.forEach((f) => f(msg));
}

function reconnectWS() {
  console.log("reconnecting ws...");
  setTimeout(() => connect(), 1000);
}

function socketAuthToken(socket) {
  if (socket == null) {
    return;
  }

  return new URL(socket.url).searchParams.get("auth_token");
}

function connect() {
  const wsConfig = getMutableConfig();
  if (!wsConfig.running) {
    return;
  }

  const newAuthToken = getAuthenticationToken();
  const oldAuthToken = socketAuthToken(wsConfig.socket);

  if (oldAuthToken !== newAuthToken) {
    wsConfig.socket?.close();
    wsConfig.socket = null;
  }

  if (wsConfig.socket && wsConfig.socket.readyState === WebSocket.OPEN) {
    return;
  }

  const socket = new WebSocket(
    `${config.apiWs}?auth_token=${encodeURIComponent(newAuthToken)}`,
  );

  socket.addEventListener("open", () => {
    onMessage({ type: "__connect" });
  });

  socket.addEventListener("close", () => {
    reconnectWS();
  });

  socket.addEventListener("message", (event) => {
    onMessage(JSON.parse(event.data));
  });

  wsConfig.socket?.close();
  wsConfig.socket = socket;
}

export function connectWS() {
  getMutableConfig().running = true;
  connect();
}

export function disconnectWs() {
  const wsConfig = getMutableConfig();
  wsConfig.running = false;
  wsConfig.socket?.close();
  wsConfig.socket = null;
}
