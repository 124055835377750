import * as React from "react";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import NotificationsList from "./NotificationList";
import { useNotifications } from "../../context/NotificationContext";

/**
 * NotificationMenu component renders a dropdown menu that displays the latest notifications
 * and provides options to view all notifications, mark them as read/unread, and navigate to the notifications page.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {HTMLElement} props.anchorEl - The element to which the menu is anchored.
 * @param {boolean} props.open - Boolean indicating whether the menu is open.
 * @param {function} props.handleClose - Function to handle the closing of the menu.
 * @param {function} props.navigate - Function to navigate to the notifications page.
 * @returns {JSX.Element}
 */

export default function NotificationMenu({
  anchorEl,
  open,
  handleClose,
  navigate,
}) {
  const { notifications, setNotifications, markAllAsRead } = useNotifications();
  const { t } = useTranslation();

  /**
   * Sort notifications by date in descending order.
   *
   * @type {Array<Object>}
   */
  const sortedNotifications = [...notifications].sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  /**
   * Get the five latest notifications.
   *
   * @type {Array<Object>}
   */
  const latestNotifications = sortedNotifications.slice(0, 5);

  /**
   * Boolean indicating if all latest notifications are marked as read.
   *
   * @type {boolean}
   */
  const allRead = latestNotifications.every((item) => item.isRead);

  /**
   * Handles clicking on a notification item, toggling its read/unread status.
   *
   * @param {string} id - The ID of the clicked notification.
   * @returns {void}
   */
  const handleItemClick = (id) => {
    setNotifications((prevNotifications) => {
      return prevNotifications.map((item) =>
        item.id === id ? { ...item, isRead: !item.isRead } : item,
      );
    });
  };

  /**
   * Handles marking all latest notifications as read or unread.
   *
   * @returns {void}
   */
  const handleMarkAll = () => {
    markAllAsRead(latestNotifications, allRead);
  };

  /**
   * Handles navigating to the notifications page where all notifications are displayed.
   *
   * @returns {void}
   */
  const handleViewNotifications = () => {
    navigate("/notifications", { state: { notifications } });
    handleClose();
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: 850, maxWidth: "100%", padding: 2 } }}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem onClick={handleViewNotifications}>
        <ListItemText>{t("client.notifications.title")}</ListItemText>
        <ListItemIcon>
          <FontAwesomeIcon icon={faEllipsis} />
        </ListItemIcon>
      </MenuItem>
      <Divider />
      <MenuItem>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <ListItemText>
            <Box display="flex" justifyContent="space-between">
              <Button onClick={handleViewNotifications} disableRipple>
                {t("client.notifications.seeMore")}
              </Button>
              <Button
                onClick={handleMarkAll}
                sx={{ cursor: "pointer" }}
                disableRipple
              >
                {!allRead
                  ? t("client.notifications.markAllAsRead")
                  : t("client.notifications.markAllAsUnread")}
              </Button>
            </Box>
          </ListItemText>
        </Box>
      </MenuItem>
      <NotificationsList
        notifications={latestNotifications}
        handleItemClick={handleItemClick}
      />
      <Divider />
      <MenuItem sx={{ display: "flex", justifyContent: "space-between" }}>
        <ListItemText primary={t("client.notifications.new")} />
        <ListItemText
          align="right"
          secondary={t("client.notifications.emailNotification")}
        />
      </MenuItem>
    </Menu>
  );
}
