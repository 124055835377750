import React from "react";
import { Avatar, Box, Typography, Tooltip } from "@mui/material";
import CakeIcon from "@mui/icons-material/Cake";
import PersonIcon from "@mui/icons-material/Person";

function ClientAvatar({ client, t, isBirthdayToday }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
      <Avatar
        sx={{
          width: 56,
          height: 56,
          mr: 2,
          bgcolor: "grey.200",
        }}
      >
        <PersonIcon sx={{ fontSize: 32, color: "grey.500" }} />
      </Avatar>
      <Box>
        <Typography variant="h4" component="h1">
          {client
            ? `${client.first_name} ${client.last_name}`
            : t("client.notFound")}
        </Typography>
        {isBirthdayToday && (
          <Tooltip title={t("client.happyBirthday")} arrow>
            <CakeIcon sx={{ color: "primary.main", ml: 1 }} />
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}

export default ClientAvatar;
