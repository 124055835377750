import React from "react";
import { Paper, Typography, List, ListItem, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

/**
 * @typedef {Object} LegalGuardian
 * @property {string} name
 * @property {string} relationship
 */

/**
 * @typedef {Object} LegalGuardianCardProps
 * @property {LegalGuardian[]} legalGuardians
 */

/**
 * @param {LegalGuardianCardProps} props
 */
const LegalGuardianCard = ({ legalGuardians }) => {
  const { t } = useTranslation();

  const cardStyle = {
    padding: 2,
    marginBottom: 2,
    maxWidth: 300,
    minHeight: 200,
  };

  return (
    <Paper elevation={3} sx={cardStyle}>
      <Typography variant="h6">{t("legalGuardians.card.title")}</Typography>
      {legalGuardians.length > 0 ? (
        <List>
          {legalGuardians.map((guardian) => (
            <ListItem key={guardian.id}>
              <ListItemText
                primary={`${guardian.first_name} ${guardian.last_name}`}
                secondary={
                  <>
                    <strong>{t("legalGuardians.card.phone")}:</strong>{" "}
                    {guardian.phone || t("legalGuardians.card.noPhone")}
                    <br />
                    <strong>{t("legalGuardians.card.email")}:</strong>{" "}
                    {guardian.email || t("legalGuardians.card.noEmail")}
                    <br />
                    <strong>
                      {t("legalGuardians.card.birth_number")}:
                    </strong>{" "}
                    {guardian.birth_number}
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body1">
          {t("legalGuardians.noGuardians")}
        </Typography>
      )}
    </Paper>
  );
};

export default LegalGuardianCard;
