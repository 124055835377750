import { Card, CardContent, Typography } from "@mui/material";
import React from "react";

export default function Homepage() {
  return (
    <Card>
      <CardContent>
        <Typography variant="h1">Homepage</Typography>
      </CardContent>
    </Card>
  );
}
