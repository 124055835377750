import Rollbar from "rollbar";
import { config } from "./config/config";

const rollbar =
  location.hostname === "localhost"
    ? null
    : new Rollbar({
        accessToken: "c8f74d3fcf68494180c77c32afcd8a5d",
        environment: config.env,
        captureUncaught: true,
        captureUnhandledRejections: true,
        code_version: import.meta.env.VITE_APP_VERSION,
      });

export function error(msg) {
  console.error(msg);
  rollbar?.error(msg);
}
