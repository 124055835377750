import React, {
  useContext,
  useState,
  createContext,
  useEffect,
  useMemo,
} from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useMsal } from "@azure/msal-react";

const AuthContext = createContext(null);

const createMsalConfig = (stayLoggedIn) => ({
  auth: {
    clientId: import.meta.env.VITE_AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${import.meta.env.VITE_AZURE_TENANT_ID}`,
    redirectUri: `${window.location.origin}/login`,
  },
  cache: {
    cacheLocation: stayLoggedIn ? "localStorage" : "sessionStorage",
    storeAuthStateInCookie: false,
  },
});

/**
 * AuthProvider component that provides authentication context to its children.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components.
 * @returns {JSX.Element} The AuthProvider component.
 */
function AuthStateProvider({ children, setUser }) {
  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0) {
      const activeAccount = accounts[0];
      setUser({
        id: activeAccount.localAccountId,
        username: activeAccount.username,
        name: activeAccount.name,
      });
    } else {
      setUser(null);
    }
  }, [accounts, setUser]);

  return children;
}

export function AuthProvider({ children }) {
  const [stayLoggedIn, setStayLoggedIn] = useState(false);
  const [intendedUrl, setIntendedUrl] = useState(null);
  const [user, setUser] = useState(null);

  const msalInstance = useMemo(() => {
    const msalConfig = createMsalConfig(stayLoggedIn);
    return new PublicClientApplication(msalConfig);
  }, [stayLoggedIn]);

  /**
   * Saves the intended URL to the state.
   * @param {string} url - The URL to save.
   */
  const saveIntendedUrl = (url) => {
    setIntendedUrl(url);
  };

  /**
   * Clears the intended URL from the state.
   */
  const clearIntendedUrl = () => {
    setIntendedUrl(null);
  };

  const authContextValue = {
    user,
    saveIntendedUrl,
    clearIntendedUrl,
    intendedUrl,
    msalInstance,
    isAuthenticated: !!user,
    stayLoggedIn,
    setStayLoggedIn,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      <MsalProvider instance={msalInstance}>
        <AuthStateProvider setUser={setUser}>{children}</AuthStateProvider>
      </MsalProvider>
    </AuthContext.Provider>
  );
}

/**
 * Custom hook to use the authentication context.
 * @returns {Object} The authentication context value.
 */
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthProvider;
