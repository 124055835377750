import React, { useEffect } from "react";
import { useLocation } from "react-router";
import Layout from "./Layout.jsx";
import { saveIntendedUrl } from "../utils/util.js";
import { useAuthenticationToken } from "../context/AuthenticationTokenContext.jsx";
import { useNavigate } from "../url.js";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [authToken] = useAuthenticationToken();

  useEffect(() => {
    if (authToken == null) {
      saveIntendedUrl(location.pathname + location.search);
      navigate("/login", { replace: true });
    }
  }, [location.pathname, navigate, authToken]);

  if (authToken == null) {
    return null;
  }

  return <Layout>{children}</Layout>;
};

export default ProtectedRoute;
