import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRevalidator } from "react-router";
import { softDeleteService } from "../../services/api";

export function DeleteServiceDialog({ service, onClose }) {
  const { t } = useTranslation();
  const revalidator = useRevalidator();

  const handleDelete = async () => {
    await softDeleteService(service.id);
    revalidator.revalidate();
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{t("service.deleteTitle")}</DialogTitle>
      <DialogContent>
        {t("service.deleteConfirm", { name: service.name })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("form.no")}</Button>
        <Button onClick={handleDelete} color="error">
          {t("form.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
