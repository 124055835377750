import React from "react";
import { Paper, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Tag from "../../common/Tag";

/**
 * @typedef {Object} RiskAllergy
 * @property {string} name
 * @property {string} description
 */

/**
 * @typedef {Object} RiskAllergyCardProps
 * @property {RiskAllergy[]} riskAllergies
 */

/**
 * @param {RiskAllergyCardProps} props
 */
const RiskAllergyCard = ({ riskAllergies = [] }) => {
  const { t } = useTranslation();

  const risks = riskAllergies.filter((item) => item.type === "risk");
  const allergies = riskAllergies.filter((item) => item.type === "allergy");

  const cardStyle = {
    padding: 2,
    marginBottom: 2,
    maxWidth: 300,
    minHeight: 200,
  };

  return (
    <Paper elevation={3} sx={cardStyle}>
      <Typography variant="h6">{t("riskAllergies.card.title")}</Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: 2 }}>
        {risks.map((risk) => (
          <Tag key={risk.id} label={risk.name} color="#f44336" />
        ))}
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {allergies.map((allergy) => (
          <Tag key={allergy.id} label={allergy.name} color="#2196f3" />
        ))}
      </Box>
    </Paper>
  );
};

export default RiskAllergyCard;
