import * as React from "react";
import { MenuItem } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
  faTriangleExclamation,
  faCircleInfo,
  faCircleCheck,
  faEye,
  faEyeSlash,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import formatDate from "../../utils/date";

const iconMap = {
  warning: faTriangleExclamation,
  success: faCircleCheck,
  info: faCircleInfo,
  unread: faEyeSlash,
  read: faEye,
  faEllipsis: faEllipsis,
};

/**
 * NotificationItem component renders an individual notification item with a category icon, primary and secondary text,
 * the date it was created, and an "eye" icon to indicate read/unread. It allows the user to click on it to trigger a notification action.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.item - The notification item to display.
 * @param {string} props.item.id - The unique identifier for the notification.
 * @param {string} props.item.type - The type of the notification.
 * @param {string} props.item.title - The title of the notification.
 * @param {string} props.item.description - Secondary text for the notification.
 * @param {string} props.item.linkHref - The URL for the notification link.
 * @param {string} props.item.linkText - The text for the notification link.
 * @param {Date} props.item.date - The date the notification was created.
 * @param {boolean} props.item.isRead - Indicates whether the notification has been read.
 * @param {string} props.item.category - The category of the notification (used for icons) - success, warning, info.
 * @param {function} props.handleItemClick - Function to handle the click event on the notification item.
 * @returns {JSX.Element|null} Returns a JSX element representing the notification item, or null if no item is provided.
 */

export default function NotificationItem({ item, handleItemClick }) {
  const { t, i18n } = useTranslation();
  const translatedTitle = t(item.title);
  const translatedDescription = t(item.description);

  if (!item) {
    return null;
  }

  const formattedDate = formatDate(item.date);
  const dateString =
    formattedDate.type === "full"
      ? t("date.full", {
          date: new Date(item.date).toLocaleDateString(i18n.language, {
            day: "numeric",
            month: "long",
            year: "numeric",
          }),
          time: formattedDate.time,
        })
      : t(`date.${formattedDate.type}`, { time: formattedDate.time });

  return (
    <MenuItem onClick={() => handleItemClick(item.id)}>
      <ListItemIcon>
        <FontAwesomeIcon icon={iconMap[item.category]} />
      </ListItemIcon>
      <Box sx={{ width: "60%" }}>
        <ListItemText>{translatedTitle}</ListItemText>
        <ListItemText variant="body2" sx={{ color: "text.secondary" }}>
          {translatedDescription} <a href={item.linkHref}>{t(item.linkText)}</a>
        </ListItemText>
      </Box>
      <ListItemText>{dateString}</ListItemText>
      <ListItemIcon>
        <FontAwesomeIcon icon={iconMap[item.isRead ? "read" : "unread"]} />
      </ListItemIcon>
    </MenuItem>
  );
}
