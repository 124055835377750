// components/NotificationFilters.js
import React from "react";
import {
  Box,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const NotificationFilters = ({
  showUnreadOnly,
  toggleShowUnreadOnly,
  sortDirection,
  handleSortDirectionChange,
  allRead,
  handleMarkAll,
  setSearchTerm,
  setPage,
}) => {
  const { t } = useTranslation();

  /**
   * NotificationFilters component allows users to filter notifications
   * by unread status, sort direction, and search by category.
   *
   * @component
   * @param {Object} props - The props for the component.
   * @param {boolean} props.showUnreadOnly - Indicates whether to show only unread notifications.
   * @param {function} props.toggleShowUnreadOnly - Function to toggle showing unread notifications.
   * @param {string} props.sortDirection - The current sorting direction ("asc" or "desc").
   * @param {function} props.handleSortDirectionChange - Function to handle sorting direction change.
   * @param {boolean} props.allRead - Indicates if all notifications have been read.
   * @param {function} props.handleMarkAll - Function to mark all notifications as read/unread.
   * @param {function} props.setSearchTerm - Function to update the search term.
   * @param {function} props.setPage - Function to set the current page of notifications.
   * @returns {JSX.Element} Returns a JSX element for the notification filters.
   * @example
   */

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box display={"inline-flex"} alignItems={"flex-start"}>
          <Typography variant="h4" gutterBottom>
            {showUnreadOnly
              ? t("client.notifications.unreadNotifications")
              : t("client.notifications.allNotifications")}{" "}
          </Typography>
          <Button
            align="right"
            onClick={handleMarkAll}
            sx={{ cursor: "pointer" }}
          >
            {!allRead
              ? t("client.notifications.markAllAsRead")
              : t("client.notifications.markAllAsUnread")}
          </Button>
        </Box>
        <Box>
          <TextField
            id="notification-search"
            label={t("client.notifications.searchByCategory")}
            variant="outlined"
            size="small"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPage(0); // Reset to first page when changing search term
            }}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent={"space-between"}>
        <FormControlLabel
          control={
            <Switch checked={showUnreadOnly} onChange={toggleShowUnreadOnly} />
          }
          label={
            showUnreadOnly
              ? t("client.notifications.unread")
              : t("client.notifications.all")
          }
        />
        <Select
          value={sortDirection}
          onChange={handleSortDirectionChange}
          sx={{ marginBottom: 2 }}
        >
          <MenuItem value="asc">
            {t("client.notifications.sortByOldest")}
          </MenuItem>
          <MenuItem value="desc">
            {t("client.notifications.sortByNewest")}
          </MenuItem>
        </Select>
      </Box>
    </>
  );
};

export default NotificationFilters;
