import React from "react";
import { Paper, Typography, List, ListItem, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

/**
 * @typedef {Object} TreatmentType
 * @property {string} name
 * @property {string} description
 */

/**
 * @typedef {Object} TreatmentTypeCardProps
 * @property {TreatmentType[]} treatmentTypes
 */

/**
 * @param {TreatmentTypeCardProps} props
 */
const TreatmentTypeCard = ({ treatmentTypes = [] }) => {
  const { t } = useTranslation();

  // TODO this styling will go away as it's not following DRY
  const cardStyle = {
    padding: 2,
    marginBottom: 2,
    maxWidth: 300,
    minHeight: 200,
  };

  return (
    <Paper elevation={3} sx={cardStyle}>
      <Typography variant="h6">{t("treatment.card.title")}</Typography>
      <List>
        {treatmentTypes.map((treatment) => (
          <ListItem key={treatment.id}>
            <ListItemText primary={treatment.treatment} />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default TreatmentTypeCard;
