import React from "react";

import { Box, Dialog, DialogContent } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { dateInput } from "../utils/formatter";

function Empty() {
  return null;
}

function months() {
  const startOfMonth = new Date();
  startOfMonth.setDate(1);
  startOfMonth.setHours(0, 0, 0, 0);

  return Array.from(new Array(12).keys()).map((add) => {
    const d = new Date(startOfMonth.getTime());
    d.setMonth(d.getMonth() + add);

    return d;
  });
}

export function DatePicker({ close, onChange }) {
  const [value, setValue] = React.useState(null);

  return (
    <Dialog
      open={true}
      onClose={() => close()}
      PaperProps={{ sx: { maxWidth: "none" } }}
    >
      <DialogContent>
        <Box display="grid" gridTemplateColumns="repeat(4, 1fr)">
          {months().map((m) => (
            <DateCalendar
              key={m.toISOString()}
              views={["day"]}
              slots={{ nextIconButton: Empty, previousIconButton: Empty }}
              referenceDate={dayjs(m)}
              value={value?.$d.getMonth() === m.getMonth() ? value : null}
              onChange={(value) => {
                setValue(value);
                onChange(dateInput(value.$d));
              }}
            />
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
