/**
 * @see {@link https://gist.github.com/jakearchibald/cb03f15670817001b1157e62a076fe95}
 *
 * @param {number} ms
 * @param {AbortSignal} signal
 * @param {Function} callback
 */
export function animationInterval(ms, signal, callback) {
  const start = document.timeline
    ? document.timeline.currentTime
    : performance.now();

  function frame(time) {
    if (signal.aborted) return;
    callback(time);
    scheduleFrame(time);
  }

  function scheduleFrame(time) {
    const elapsed = time - start;
    const roundedElapsed = Math.round(elapsed / ms) * ms;
    const targetNext = start + roundedElapsed + ms;
    const delay = targetNext - performance.now();
    setTimeout(() => requestAnimationFrame(frame), delay);
  }

  scheduleFrame(start);
}

export const saveIntendedUrl = (url) => {
  localStorage.setItem("intendedUrl", url);
};

export const getIntendedUrl = () => {
  return localStorage.getItem("intendedUrl");
};

export const clearIntendedUrl = () => {
  localStorage.removeItem("intendedUrl");
};
