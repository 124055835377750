import React from "react";
import { isAbortError } from "shared/src/util.mjs";

/**
 * Runs `f` ignoring abort error.
 *
 * @param {() => void} f
 */
async function runAbortable(f) {
  try {
    await f();
  } catch (e) {
    if (isAbortError(e)) {
      return;
    }

    throw e;
  }
}

/**
 * Accepts a function with signal that's gonna be aborted on following call
 *
 * @param {(signal: AbortSignal) => Promise<void> | void} f
 * @param {React.DependencyList} deps
 */
export function useLatest(f, deps) {
  React.useEffect(() => {
    const controller = new AbortController();
    runAbortable(async () => {
      await f(controller.signal);
    });

    return function () {
      controller.abort();
    };
  }, deps);
}
