import React, { useState, useEffect } from "react";
import { Paper, Typography, CircularProgress } from "@mui/material";
import { getNextAppointmentForClient } from "../../../services/api";
import { useTranslation } from "react-i18next";

/**
 * @typedef {Object} NextAppointmentCardProps
 * @property {string} clientId
 */

/**
 * @param {NextAppointmentCardProps} props
 */
const NextAppointmentCard = ({ clientId }) => {
  const { t } = useTranslation();
  const [nextAppointment, setNextAppointment] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNextAppointment = async () => {
      try {
        const result = await getNextAppointmentForClient(clientId);
        if (result.data && result.data.appointments.data.length > 0) {
          setNextAppointment(result.data.appointments.data[0]);
        }
      } catch (error) {
        console.error("Failed to fetch the next appointment:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNextAppointment();
  }, [clientId]);

  const formatAppointmentDate = (startDate) => {
    const appointmentDate = new Date(startDate);
    const today = new Date();

    const formattedTime = appointmentDate.toLocaleTimeString("cs-CZ", {
      hour: "2-digit",
      minute: "2-digit",
    });

    if (
      appointmentDate.getDate() === today.getDate() &&
      appointmentDate.getMonth() === today.getMonth() &&
      appointmentDate.getFullYear() === today.getFullYear()
    ) {
      return `${t("nextAppointmentCard.todayAt")} ${formattedTime}`;
    } else {
      return `${appointmentDate.toLocaleDateString("cs-CZ")} ${t(
        "nextAppointmentCard.at",
      )} ${formattedTime}`;
    }
  };

  const cardStyle = {
    padding: 2,
    marginBottom: 2,
    maxWidth: 300,
    minHeight: 200,
  };

  return (
    <Paper elevation={3} sx={cardStyle}>
      <Typography variant="h6">{t("nextAppointmentCard.title")}</Typography>
      {loading ? (
        <CircularProgress />
      ) : nextAppointment ? (
        <div>
          <Typography variant="body1">
            <strong>{t("nextAppointmentCard.service")}:</strong>{" "}
            {nextAppointment.service.name}
          </Typography>
          <Typography variant="body1">
            <strong>{t("nextAppointmentCard.date")}:</strong>{" "}
            {formatAppointmentDate(nextAppointment.start)}
          </Typography>
        </div>
      ) : (
        <Typography
          variant="body1"
          sx={{
            wordWrap: "break-word",
          }}
        >
          {t("nextAppointmentCard.noUpcomingAppointments")}
        </Typography>
      )}
    </Paper>
  );
};

export default NextAppointmentCard;
