import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

/**
 * @typedef {Object} TimerWidgetProps
 * @property {boolean} isActive
 * @property {number} activeStep
 */

/**
 * @param {TimerWidgetProps} props
 */
const TimerWidget = ({ isActive, activeStep }) => {
  const [seconds, setSeconds] = useState(() => {
    const savedTime = localStorage.getItem("timerValue");
    return savedTime ? parseInt(savedTime, 10) : 0;
  });

  useEffect(() => {
    let timer;
    if (isActive && activeStep < 2) {
      timer = setInterval(() => {
        setSeconds((prev) => {
          const newTime = prev + 1;
          localStorage.setItem("timerValue", newTime);
          return newTime;
        });
      }, 1000);
    } else {
      clearInterval(timer);
      if (activeStep === 2) {
        localStorage.setItem("timerValue", seconds);
      }
    }

    return () => clearInterval(timer);
  }, [isActive, activeStep]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <Box sx={{ textAlign: "center", marginTop: 2 }}>
      <Typography variant="h6">Elapsed Time: {formatTime(seconds)}</Typography>
    </Box>
  );
};

export default TimerWidget;
