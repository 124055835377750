import React, { createContext, useContext, useState, useEffect } from "react";
import { prefillFormData } from "../../utils/formPrefill";

const WizardContext = createContext();

export const useWizardContext = () => {
  const context = useContext(WizardContext);
  if (!context) {
    throw new Error("useWizardContext must be used within a WizardProvider");
  }
  return context;
};

export const WizardProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [nurseNotes, setNurseNotes] = useState("");
  const [clientData, setClientData] = useState(null);

  useEffect(() => {
    const savedStep = localStorage.getItem("activeStep");
    const savedNotes = localStorage.getItem("nurseNotes");
    if (savedStep) {
      setActiveStep(parseInt(savedStep, 10));
    }
    if (savedNotes) {
      setNurseNotes(savedNotes);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("activeStep", activeStep);
    localStorage.setItem("nurseNotes", nurseNotes);
  }, [activeStep, nurseNotes]);

  const getFormPrefillData = (template) => {
    if (!template) return {};

    return prefillFormData(template, {
      nurseNotes,
      client: clientData?.client,
      riskAllergies: clientData?.riskAllergies,
      treatmentTypes: clientData?.treatmentTypes,
    });
  };

  return (
    <WizardContext.Provider
      value={{
        activeStep,
        setActiveStep,
        nurseNotes,
        setNurseNotes,
        clientData,
        setClientData,
        getFormPrefillData,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
};
