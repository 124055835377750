import { useTranslation } from "react-i18next";
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useMemo } from "react";

const SHORT_DURATION_THRESHOLD = 40;

export const ServicesSelectInput = ({
  onChange,
  value,
  services,
  labelKey,
}) => {
  const { t } = useTranslation();

  const sortedServices = useMemo(() => {
    if (services.length > 0) {
      return services.reduce(
        (acc, service) => {
          if (service.duration <= SHORT_DURATION_THRESHOLD) {
            console.log(service);
            acc.short.push({
              name: service.name,
              id: service.id,
              template:
                service.templates.length > 0
                  ? service.templates[0].template
                  : null,
            });
          } else {
            acc.long.push({
              name: service.name,
              id: service.id,
              template:
                service.templates.length > 0
                  ? service.templates[0].template
                  : null,
            });
          }
          return acc;
        },
        { short: [], long: [] },
      );
    }
    return { short: [], long: [] };
  }, [services]);
  return (
    <FormControl sx={{ my: 1, width: "100%" }}>
      <InputLabel htmlFor="service">{t(labelKey)}</InputLabel>
      <Select
        name="service"
        value={value}
        id="select"
        label={t(labelKey)}
        onChange={onChange}
      >
        {sortedServices.short && sortedServices.short.length > 0 && (
          <ListSubheader>{t("appointmentNotes.serviceShort")}</ListSubheader>
        )}
        {sortedServices.short &&
          sortedServices.short.length > 0 &&
          sortedServices.short.map((value) => (
            <MenuItem key={value.id} value={value.id}>
              {value.name}
            </MenuItem>
          ))}
        {sortedServices.long && sortedServices.long.length > 0 && (
          <ListSubheader>{t("appointmentNotes.serviceLong")}</ListSubheader>
        )}
        {sortedServices.long &&
          sortedServices.long.length > 0 &&
          sortedServices.long.map((value) => (
            <MenuItem key={value.id} value={value.id}>
              {value.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ServicesSelectInput;
